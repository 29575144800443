.banner-page-open {
  align-items: center;
  justify-content: center;
  display: flex;
  width: fit-content;
  height: fit-content;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 90%;
  z-index: 1;
  /* margin-right: 50px; */
  margin: auto;
}
.banner-container-mobile {
  display: none;
}
.banner-page-closed {
  display: none;
}
.banner-container {
  /* background-color: red; */
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.banner-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  text-align: right;
  color: #6d340b;
  text-align: center;
}

.banner-see-pricing-hide {
  display: none;
}

@media only screen and (max-width: 760px) {
  .banner-container-mobile {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner-container {
    display: none;
  }
}

.pricing-section {
  background-color: var(--bastille);
  height: fit-content;
  width: 100%;
  overflow: hidden;
}
.pricing-section-wrapper {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  margin: auto;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-container-left {
  display: block;
  margin-left: 120px;
}

.pricing-container-right {
  display: flex;
  margin-right: 120px;
}
.pricing-heading {
  font-size: 48px;
  font-family: var(--font-family-poppins);
  color: var(--white);
}
.pricing-heading-span {
  font-size: 48px;
  font-family: var(--font-family-poppins);
  color: var(--sunglow);
}
.pricing-subtext {
  font-size: 20px;
  font-family: var(--font-family-inter);
  color: var(--white);
  opacity: 0.5;
  text-align: left;
  margin-top: 2%;
  width: 65%;
}
.pricing-subtext2 {
  font-size: 20px;
  font-family: var(--font-family-inter);
  color: var(--white);
  opacity: 0.5;
  text-align: left;
  margin-top: 5%;
  width: 65%;
}
.pricing-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  border: 1px none;
  position: absolute;
  width: 360px;
  height: 56px;

  margin-top: 3%;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  border-color: rgba(255, 255, 255, 0.08);
}
.pricing-button-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  font-family: var(--font-family-inter);
  color: var(--white);
}
.pricing-info-container {
  position: relative;
  width: 384px;
  height: 480px;
  margin-right: 20px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 24px;
}
.pricing-info-heading {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: 32px;
  font-weight: 600;
  line-height: 135%;
  margin-top: 10%;
  margin-left: 10%;
}
.pricing-info-subtext {
  color: var(--white);
  font-size: 16px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  line-height: 170%;
  opacity: 0.7;
  margin-top: 5%;
  margin-left: 40px;
}
.pricing-info-divider {
  width: 320px;
  height: 0px;
  margin-top: 10%;
  opacity: 0.1;
  margin-left: 8%;
  border: 2px solid #ffffff;
}
.pricing-info-price {
  font-size: 32px;
  font-family: var(--font-family-inter);
  font-weight: 700;
  line-height: 40px;
  color: var(--white);
  margin-left: 10%;
  margin-top: 10%;
}
.pricing-info-button {
  background: var(--sunglow);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  gap: 10px;

  width: 320px;
  height: 56px;

  border: 1px none;
  margin-top: 5%;
  margin-left: 8%;
  border-radius: 8px;
}
.pricing-info-price-span {
  opacity: 0.5;
  font-size: 20px;
  margin-left: 5px;
  font-weight: 400;
}
.pricing-info-button-text {
  font-family: var(--font-family-source_sans_pro);
  font-size: 20px;
  line-height: 135%;
  font-weight: 700;
  font-style: normal;
  color: var(--black);
}
.pricing-subtext-mobile {
  display: none;
}
.pricing-button-mobile {
  display: none;
}
@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .pricing-section {
    height: 100%;
  }
  .pricing-section-wrapper {
    flex-direction: column;
  }
  .pricing-container-left {
    margin: 0 auto;
    margin-bottom: -50px;
  }
  .pricing-container-right {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .pricing-heading {
    text-align: center;
    font-size: 40px;
  }
  .pricing-heading-span {
    text-align: center;
    font-size: 40px;
  }
  .pricing-subtext {
    text-align: center;
    margin: 0 auto;
    margin-top: 2%;
    width: 90%;
    font-size: 18px;
  }
  .pricing-subtext2 {
    display: none;
  }
  .pricing-info-container {
    margin-top: 20%;
  }
  .pricing-info-heading {
    font-size: 28px;
  }
  .pricing-info-subtext {
    font-size: 16px;
  }
  .pricing-button {
    display: none;
  }
  .pricing-info-price {
    font-size: 28px;
  }
  .pricing-info-button-text {
    font-size: 20px;
  }
  .pricing-subtext-mobile {
    display: flex;
    font-size: 18px;
    font-family: var(--font-family-inter);
    color: var(--white);
    opacity: 0.5;
    margin-top: 15%;
    align-self: center;
  }
  .pricing-button-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    border: 1px none;
    height: 56px;
    border-color: rgba(255, 255, 255, 0.08);
    margin-top: 5%;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
  }
  .pricing-button-text {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .pricing-section {
    height: 100%;
  }
  .pricing-section-wrapper {
    flex-direction: column;
  }
  .pricing-container-left {
    margin: 0 auto;
    margin-bottom: -50px;
  }
  .pricing-container-right {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .pricing-heading {
    text-align: center;
    font-size: 28px;
  }
  .pricing-heading-span {
    text-align: center;
    font-size: 28px;
  }
  .pricing-subtext {
    text-align: center;
    margin: 0 auto;
    margin-top: 2%;
    width: 90%;
    font-size: 12px;
  }
  .pricing-subtext2 {
    display: none;
  }
  .pricing-info-container {
    margin-top: 20%;
    width: 296px;
    height: 330px;
  }
  .pricing-info-heading {
    font-size: 20px;
    font-weight: 500;
    margin-top: 7%;
    margin-left: 7%;
  }
  .pricing-info-subtext {
    font-size: 14px;
    margin-left: 20px;
  }
  .pricing-info-divider {
    width: 264px;
    margin-left: 6%;
    margin-top: 4%;
  }
  .pricing-info-button {
    width: 264px;
    height: 48px;
    margin-left: 6%;
    margin-top: 2%;
  }
  .pricing-info-button-text {
    font-size: 16px;
  }
  .pricing-button {
    display: none;
  }
  .pricing-info-price {
    font-size: 20px;
    margin-top: 3%;
  }
  .pricing-info-price-span {
    font-size: 18px;
  }
  .pricing-subtext-mobile {
    display: flex;
    font-size: 12px;
    font-family: var(--font-family-inter);
    color: var(--white);
    opacity: 0.5;
    margin-top: 15%;
    align-self: center;
  }
  .pricing-button-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    border: 1px none;
    height: 56px;

    margin-top: 5%;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
  }
  .pricing-button-text {
    font-size: 16px;
  }
}

.how-webridge-section {
  color: #fff;
  background: var(--bastille);
  width: 100%;
}
.how-webridge-title {
  letter-spacing: 0.96px;
  min-height: 72px;
  min-width: 842px;
  text-align: center;
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 44px;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 7%;
}
.how-webridge-span {
  letter-spacing: 0.96px;
  min-height: 72px;
  min-width: 842px;
  text-align: center;
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-weight: 600;
  font-style: normal;
  margin-top: 10%;
  margin-bottom: 5%;
  color: var(--sunglow);
}
.how-webridge-title-noshow {
  display: none;
}
.how-webridge-wrapper {
  display: grid;
  z-index: 1;
  height: fit-content;
  padding-top: 7%;
  width: 100%;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
}

.how-webridge-row-col2col1 {
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: "col2 col1";
}

.how-webridge-row-col1col2 {
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: "col1 col2";
}

.how-webridge-col {
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
}

.how-webridge-col2 {
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
}

.how-webridge-textwrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.how-webridge-h1 {
  letter-spacing: 0;
  line-height: 37.8px;
  color: var(--sunglow);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-28px);
  font-weight: 700;
  font-style: normal;
}

.how-webridge-subtitle {
  letter-spacing: 0;
  line-height: 25.6px;
  opacity: 0.5;
  width: 480px;
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-16px);
  font-weight: 400;
  font-style: normal;
}

.how-webridge-imagewrap {
  max-width: fit-content;
  height: 100%;
}

.how-webridge-image {
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .how-webridge-section {
    width: 100%;
  }

  .how-webridge-h1 {
    letter-spacing: 0;
    line-height: 40px;
    min-height: 22px;
    font-size: var(--font-size-28px);
    font-weight: 700;
    font-style: normal;
    margin-left: 5%;
    width: 80%;
    white-space: pre-line;
  }
  .how-webridge-title {
    color: var(--white);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-40px);
    font-weight: 600;
    min-width: 0px;
    letter-spacing: 0.56px;
    min-height: 84px;
    text-align: center;
    width: 100%;
    margin-left: 0%;
    margin-top: 0%;
  }
  .how-webridge-span {
    color: var(--sunglow);
    font-family: var(--font-family-poppins);
    font-weight: 600;
    min-width: 0px;
    letter-spacing: 0.56px;
    min-height: 84px;
    text-align: center;
    width: 100%;
    margin-left: 0%;
    margin-top: -5%;
  }
  .how-webridge-textwrapper {
    padding-bottom: 0;
  }

  .how-webridge-subtitle {
    letter-spacing: 0;
    line-height: 18px;
    min-height: 90px;
    opacity: 0.5;
    width: 100%;
    color: var(--white);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-14px);
    font-weight: 400;
    font-style: normal;
    margin-left: 5%;
    padding-right: 10%;
    justify-content: right;
  }
  .how-webridge-wrapper {
    height: fit-content;
    padding-top: 10%;
  }
}
@media only screen and (max-width: 425px) {
  .how-webridge-h1 {
    font-size: 22px;
    margin-top: 5%;
  }
  .how-webridge-row-col2col1 {
    grid-template-areas: "col1" "col2";
  }
  .how-webridge-row-col1col2 {
    grid-template-areas: "col1 col1" "col2 col2";
  }
  .how-webridge-section {
    width: 100%;
  }
  .how-webridge-title {
    font-size: 24px;
    min-width: 0px;
    margin-top: 5%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .how-webridge-span {
    color: var(--sunglow);

    min-width: 0px;
    margin-top: 5%;
  }
  .how-webridge-subtitle {
    font-size: 12px;
    width: 100%;
  }
  .how-webridge-wrapper {
    height: fit-content;
    padding-top: 15%;
  }
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
  .how-webridge-h1 {
    font-size: 22px;
    text-align: center;
  }
  .how-webridge-row-col2col1 {
    grid-template-areas: "col1" "col2";
  }
  .how-webridge-row-col1col2 {
    grid-template-areas: "col1 col1" "col2 col2";
  }
  .how-webridge-section {
    width: 100%;
  }
  .how-webridge-title {
    font-size: 24px;
    min-width: 0px;
    margin-top: 5%;
  }
  .how-webridge-span {
    color: var(--sunglow);
    min-width: 0px;
    margin-top: 5%;
  }
  .how-webridge-subtitle {
    font-size: 12px;
    width: 100%;
  }
  .how-webridge-wrapper {
    height: fit-content;
    padding-top: 10%;
  }
}

.privacy-policy-section {
  background-color: var(--bastille);
  height: fit-content;
  width: 100%;
  overflow: hidden;
}
.privacy-policy-wrapper {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  margin: auto;
  max-width: 1500px;
}
.privacy-heading {
  font-size: 48px;
  font-family: var(--font-family-poppins);
  color: var(--white);
  text-align: center;
  margin-top: 2%;
}
.privacy-subheading {
  font-size: 16px;
  font-family: var(--font-family-inter);
  color: var(--white);
  text-align: center;
  margin-top: 2%;
}
.privacy-container {
  margin-left: 5%;
  margin-right: 5%;
}
.privacy-subbtext-heading {
  font-size: 28px;
  font-family: var(--font-family-poppins);
  color: var(--white);
  text-align: left;
  margin-top: 5%;
}
.privacy-subtext-paragraph {
  margin-top: 2%;
  font-size: 18px;
  opacity: 0.7;
  color: var(--white);
  text-align: left;
  font-family: var(--font-family-inter);
}
@media only screen and (max-width: 767px) {
  .privacy-heading {
    font-size: 28px;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .privacy-subtext-paragraph {
    font-size: 18px;
  }
  .privacy-subbtext-heading {
    font-size: 22px;
  }
}

.hs-section {
  border: 1px none;
  height: 700px;
  width: 100%;
  background-color: var(--mirage);
}
.hs-wrapper {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
}
.hs-heading {
  color: transparent;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-40px);
  font-weight: 800;
  letter-spacing: 0.8px;
  min-height: 48px;
  min-width: 100%;
  text-align: center;
  font-style: normal;
  padding-top: 100px;
  text-align: center;
}
.hs-span-our,
.hs-span-stories {
  color: var(--white);
}
.hs-span-happy {
  color: var(--sunglow);
}

.hs-user-testimonial {
  background-color: var(--white-2);
  border-radius: 24px;
  display: inline-block;
  height: 280px;
  position: relative;
  width: 45%;
  margin-left: 2%;
  margin-top: 10%;
  margin-right: 0%;
}

.hs-user-info {
  display: flex;
  flex-direction: column;
  height: 204px;
  margin-left: 40px;
  margin-top: 40px;
  width: 148px;
}
.hs-user-img {
  border: 1px none;
  border-radius: 72px;
  height: 150px;
  margin-top: 24px;
  width: 120px;
  scale: 2.2;
}
.hs-user-name {
  height: 17px;
  letter-spacing: 0.28px;
  margin-left: 12px;
  margin-top: -24px;
  text-align: center;
  width: 130px;
  color: var(--white-5);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-14px);
  font-weight: 400;
  font-style: normal;
  text-align: left;
}

.hs-user-business-title {
  height: 15px;
  letter-spacing: 0.24px;
  margin-left: 12px;
  margin-top: 0px;
  opacity: 0.5;
  text-align: center;
  width: 120px;
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-12px);
  font-weight: 400;
  font-style: normal;
  text-align: left;
}
.hs-carousel-stack {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
}
.hs-user-review {
  display: flex;
  flex-direction: column;
  height: 192px;
  margin-left: 6%;
  margin-top: auto;
}
.hs-user-review-title {
  height: 58px;
  letter-spacing: 0.48px;
  width: 90%;
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-24px);
  font-weight: 700;
  font-style: normal;
  white-space: normal;
  line-height: 150%;
}
.hs-user-review-subtext {
  height: 110px;
  letter-spacing: 0;
  line-height: 150%;
  margin-top: 20px;
  width: 90%;
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-14px);
  font-weight: 300;
  font-style: normal;
  white-space: normal;
}

.hs-carousel-container {
  width: 100%;
  height: 80%;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: -5%;
}
.hs-user-col {
  display: flex;
  flex-direction: row;
}
.hs-carousel-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hs-carousel-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
@media only screen and (min-width: 1150px) and (max-width: 1330px) {
  .hs-user-review-title {
    font-size: 20px;
  }
  .hs-user-review-subtext {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1150px) {
  .hs-user-testimonial {
    height: 400px;
    width: 55%;
  }
  .hs-user-info {
    margin-top: 10px;
  }
  .hs-user-review-subtext {
    line-height: 170%;
  }
  .hs-user-col {
    flex-direction: column-reverse;
  }
  .hs-user-business-title {
    margin-left: 120px;
    margin-top: 5px;
    font-size: 18px;
  }
  .hs-user-name {
    margin-left: 120px;
    margin-top: -100px;
    font-size: 20px;
    color: white;
  }
  .hs-user-review {
    margin-top: 6%;
  }
  .hs-user-img {
    height: 100px;
    margin-top: 70px;
    width: 80px;
  }
}
@media only screen and (min-width: 570px) and (max-width: 767px) {
  .hs-user-testimonial {
    height: 429px;
    width: 60%;
  }
  .hs-user-col {
    flex-direction: column-reverse;
  }
  .hs-user-business-title {
    margin-left: 110px;
    margin-top: 0px;
    font-size: 16px;
  }
  .hs-user-name {
    margin-left: 110px;
    margin-top: -95px;
    font-size: 16px;
    color: white;
  }
  .hs-user-review {
    margin-top: 10%;
    font-size: 14px;
  }
  .hs-user-review-title {
    font-size: 20px;
  }
  .hs-user-img {
    height: 100px;
    width: 80px;
  }
  .hs-heading {
    font-size: 28px;
  }
}

/* @media only screen and (min-width: 430px) and (max-width: 650px) {
  .hs-user-testimonial {
    height: 429px;
    width: 90%;
  }
  .hs-user-col {
    flex-direction: column-reverse;
  }
  .hs-user-business-title {
    margin-left: 160px;
    margin-top: 20px;
    font-size: 16px;
  }
  .hs-user-name {
    margin-left: 160px;
    margin-top: -92px;
    font-size: 16px;
    color: white;
  }
  .hs-user-review {
    margin-top: 10%;
    font-size: 14px;
  }
  .hs-user-review-title {
    font-size: 20px;
  }
  .hs-user-img {
    height: 120px;
    width: 120px;
  }
  .hs-heading {
    font-size: 28px;
  }
} */

@media only screen and (max-width: 400px) {
  .hs-user-testimonial {
    height: 410px;
    width: 90%;
  }
  .hs-user-col {
    flex-direction: column-reverse;
  }
  .hs-user-business-title {
    margin-left: 70px;
    margin-top: 0px;
    font-size: 14px;
  }
  .hs-user-name {
    margin-left: 70px;
    margin-top: -85px;
    font-size: 14px;
    color: white;
  }
  .hs-user-review {
    margin-top: 10%;
    font-size: 14px;
  }
  .hs-user-review-title {
    font-size: 20px;
  }
  .hs-user-img {
    height: 80px;
    width: 65px;
    margin-top: 70px;
    margin-left: -20px;
  }
  .hs-heading {
    font-size: 28px;
  }
  .hs-user-info {
    margin-left: 45px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 570px) {
  .hs-user-testimonial {
    height: 370px;
    width: 90%;
  }
  .hs-user-col {
    flex-direction: column-reverse;
  }
  .hs-user-business-title {
    margin-left: 70px;
    margin-top: 0px;
    font-size: 14px;
  }
  .hs-user-name {
    margin-left: 70px;
    margin-top: -85px;
    font-size: 14px;
    color: white;
  }
  .hs-user-review {
    margin-top: 10%;
    font-size: 14px;
  }
  .hs-user-review-title {
    font-size: 20px;
  }
  .hs-user-img {
    height: 80px;
    width: 65px;
    margin-top: 2px;
    margin-left: -20px;
  }
  .hs-heading {
    font-size: 28px;
  }
  .hs-user-info {
    margin-left: 50px;
  }
}

.mobile-scroll {
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
}
.mobile-mockup {
  height: 533px;
  width: 498px;
}
.scroll-full-screen {
  min-height: 79vh;
  margin-left: 10%;
}
.section-4-what {
  background-color: var(--mirage);
  width: 100%;
}
.scroll-full-screen-wrapper {
  width: 40%;
}

.scroll-full-screen {
  min-height: 54vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10%;
}

.mobile-mockup-wrapper {
  width: 60%;
}

.mobile-mockup {
  position: sticky;
  top: 150px;
  transform: translateX(30%);
  border-color: #363636;
  box-shadow: 32px 32px 48px 0px #2b2d3c58;
  border-left-style: solid;
  border-left-width: 12px;
  border-radius: 32px 32px 32px 32px;
  border-right-style: solid;
  border-right-width: 12px;
  border-top-style: solid;
  border-top-width: 12px;
  border-bottom-style: solid;
  border-bottom-width: 12px;
  display: flex;
  height: 600px;
  width: fit-content;
}

.mobile-mockup-screen {
  background-color: #141624;
  overflow: hidden;
  height: 100%;
  border-radius: inherit;
}

.mobile-screen-img {
  height: 100%;
  object-fit: contain;
}

.screen-text {
  opacity: 0;
  padding-bottom: 60%;
}

.text-visible {
  opacity: 1;
  transition: 0.75s;
}

.screen-heading {
  color: white;
  font-size: 48px;
  font-weight: 700;
  line-height: 135%;
}
.screen-heading-span {
  color: var(--white);
  font-size: 48px;
  font-weight: 700;
  line-height: 135%;
}
.screen-subtext-span {
  color: var(--sunglow);
  font-size: 48px;
  font-weight: 700;
  line-height: 135%;
}

.screen-description {
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-top: 52px;
}

@media all and (max-width: 1279px) {
  .mobile-scroll {
    padding-bottom: 30px;
  }

  .screen-description {
    font-size: 18px;
    margin-top: 44px;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-in-right {
  -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@media only screen and (max-width: 767px) {
  .scroll-full-screen {
    margin-top: 0%;
    margin-left: 0%;
  }
  .mobile-mockup {
    width: 260%;
    height: 480px;
    margin-top: 20%;
    border-radius: 14.8px 14.8px 14.8px 14.8px;
    transform: translateX(0%);
    margin-left: -30%;
  }
  .non-mobile {
    display: none !important;
  }
  .section-4-what {
    min-width: 100%;
  }
  .screen-heading {
    font-size: var(--font-size-24px);
    line-height: 135%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .screen-heading-span {
    font-size: var(--font-size-24px);
    line-height: 135%;
  }
  .screen-subtext-span {
    font-size: var(--font-size-24px);
    line-height: 135%;
  }

  .scroll-full-screen-wrapper {
    width: 100%;
  }
}
.scale-in-bottom {
  -webkit-animation: scale-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
   * Generated by Animista on 2022-1-22 14:47:52
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

/**
   * ----------------------------------------
   * animation scale-in-bottom
   * ----------------------------------------
   */
@-webkit-keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
}
.max-width {
  margin: auto;
  max-width: 1500px;
}

.width-50 {
  width: 50%;
}
.flex {
  display: flex;
}

.absolute-center {
  justify-content: center;
  align-items: center;
}

@media all and (min-width: 766px) {
  .only-mobile {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .mobile-mockup {
    height: 485px;
    width: fit-content;
  }
  .screen-heading {
    font-size: 40px;
    line-height: 135%;
  }
  .screen-heading-span {
    font-size: 40px;
    line-height: 135%;
  }
  .screen-subtext-span {
    font-size: 40px;
    line-height: 135%;
  }
  .screen-description {
    font-size: 16px;
    margin-top: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-mockup {
    height: 443px;
    width: 95%;
    margin-top: 10%;
    margin-left: 0px;
  }
  .mobile-mockup-wrapper {
    margin: 0 auto;
    width: fit-content;
  }
  .screen-heading {
    text-align: center;
    line-height: 135%;
  }
  .screen-description {
    font-size: 16px;
    margin-top: 44px;
    text-align: center;
  }
  .screen-text {
    padding-bottom: 20%;
  }
}

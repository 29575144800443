.footer-section {
  background-color: var(--mirage);
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: sticky;
}
.footers-section-wrapper {
  position: relative;
  padding-top: 40px;
  padding-bottom: 15px;
  margin: auto;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer-logo {
  margin-left: -13px;
}
.footer-address {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer-address-text {
  margin-bottom: 1.67em;
  color: white;
  font-family: var(--font-family-inter);
  font-size: 18px;
  opacity: 0.5;
  margin-left: 100px;
  font-weight: 100;
}
.footer-quote-text {
  max-width: 80%;
  margin-bottom: 1.67em;
  color: white;
  font-family: var(--font-family-inter);
  font-size: 18px;
  font-weight: 100;
  opacity: 0.7;
}
.footer-social-media-links {
  display: flex;
  flex-direction: row;
  margin-top: 5%;
}
.footer-social {
  align-items: center;
  background: #373945;
  border-radius: 12px;
  border: 1px none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  width: 48px;
  padding: 10px 10px;
  color: #272525;
  font-size: 16px;
  margin-right: 15px;
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
}
.footer-container-left {
  display: block;
  margin-left: 100px;
}

.footer-container-right {
  display: flex;
  margin-right: 100px;
}
.footer-links-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 6.25em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 0.8;
}
.footer-title {
  color: var(--sunglow);
  font-size: 22px;
  font-family: var(--font-family-inter);
  font-weight: 700;
  letter-spacing: 1px;
}
.footer-link {
  padding-top: 0.73em;
  color: white;
  font-size: 18px;
  font-family: var(--font-family-inter);
  font-style: normal;
  text-decoration: none !important;
  opacity: 0.7;
}
.footer-link:hover {
  opacity: 1;
  color: white;
}
.divider-footer {
  height: 2px;
  margin-top: 0.69em;
  background-image: radial-gradient(
    circle farthest-corner at 50% 50%,
    rgba(186, 172, 242, 0.12),
    rgba(186, 172, 242, 0)
  );
}
.footer-base {
  display: flex;
  max-width: 1500px;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  margin: auto;
}
.footer-base-text {
  margin-top: 1.67em;
  margin-bottom: 0.67em;
  color: white;
  font-family: var(--font-family-poppins);
  font-size: 18px;
  opacity: 0.5;
  margin-left: 100px;
}
.cta-primary-footer {
  align-items: center;
  background-color: var(--sunglow);
  border: 1px none;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  padding: 16px 40px;
}
.cta-text-footer {
  color: #272525;
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-20px);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  min-width: 131px;
  white-space: nowrap;
}
@media only screen and (max-width: 900px) {
  .footers-section-wrapper {
    flex-direction: column;
  }
  .footer-container-right {
    margin-top: 10%;
  }
  .footer-quote-text {
    font-size: 16px;
  }
  .footer-container-left {
    margin-left: 35px;
  }
  .footer-links-container {
    margin-left: 35px;
  }
  .cta-text-footer {
    font-size: 16px;
  }
  .cta-primary-footer {
    padding: 12px 24px;
  }
  .footer-title {
    font-size: 16px;
  }
  .footer-link {
    font-size: 12px;
  }
  .footer-social {
    font-size: 12px;
    width: 38px;
    padding: 8px 8px;
  }
  .footer-base-text {
    margin-left: 35px;
    font-size: 12px;
  }
  .footer-address-text {
    margin-left: 35px;
    font-size: 12px;
  }
}
.footer-link:hover {
  cursor: pointer;
}

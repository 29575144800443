.section-2-stats {
  background-color: var(--bastille);
  border: 1px none;
  display: flex;
  height: 220px;
  justify-content: space-around;
  width: 100%;
}

.stat-wrapper {
  display: flex;
  justify-content: space-around;
  max-width: 1500px;
  width: 100%;
}
.session-count {
  display: flex;
  flex-direction: column;
  height: 130px;
  margin-top: 50px;
  width: 324px;
}

.count {
  height: 60px;
  letter-spacing: 0;
  line-height: 60px;
  margin-left: 111px;
  text-align: center;
  white-space: nowrap;
  width: 97px;
}

.label-heading {
  height: 30px;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 10px;
  text-align: center;
  white-space: nowrap;
  width: 318px;
}

.label-text {
  color: var(--sonic-silver);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-16px);
  font-weight: 400;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 100px;
  margin-top: 10px;
  text-align: center;
  white-space: nowrap;
  width: 121px;
}

.asset-count {
  display: flex;
  flex-direction: column;
  height: 100px;
  margin-top: 50px;
  width: 299px;
}

.count-1 {
  height: 60px;
  letter-spacing: 0;
  line-height: 60px;
  margin-left: 40px;
  text-align: center;
  white-space: nowrap;
  width: 215px;
}

.span1 {
  color: var(--sunglow);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-28px);
  font-weight: 700;
  line-height: 35px;
}

.label-heading-1 {
  height: 30px;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 10px;
  text-align: center;
  white-space: nowrap;
  width: 295px;
}
.label-heading-2 {
  height: 30px;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 16px;
  text-align: center;
  white-space: nowrap;
  width: 295px;
}

.inter-bold-sunglow {
  color: var(--sunglow);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-48px);
  font-weight: 700;
  font-style: normal;
}

.inter-semi-bold-white {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-24px);
  font-weight: 600;
  font-style: normal;
}
@media only screen and (max-width: 767px) {
  .section-2-stats {
    flex-direction: column;
    height: 280px;
  }
  .session-count {
    align-self: center;
    font-size: 22px;
    margin-top: 25px;
  }
  .asset-count {
    align-self: center;
    font-size: 22px;
    margin-top: 10px;
  }
  .inter-bold-sunglow {
    font-size: 32px;
  }
  .inter-semi-bold-white {
    font-size: 32px;
  }
  .label-heading-2 {
    font-size: 16px;
    margin-top: -10px;
  }
  .label-heading-1 {
    font-size: 16px;
    margin-top: -20px;
    margin-left: 20px;
  }
  .label-text {
    font-size: 12px;
  }
  .stat-wrapper {
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .inter-bold-sunglow {
    font-size: 44px;
  }
  .inter-semi-bold-white {
    font-size: 18px;
  }
  .label-text {
    font-size: 14px;
  }
  .stat-wrapper {
    width: 85%;
  }
}

.section-3-why-section {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  height: 500px;
  padding: 72.6px 0;
  width: 100%;
  overflow: hidden;
  justify-content: space-around;
  background-color: var(--mirage);
}
.why-section-wrapper {
  width: 100%;
  justify-content: space-around;
  max-width: 1500px;
  display: flex;
}
.overlap-group1-why-section {
  height: 388px;
  position: relative;
  width: 100%;
}
.bg-asset-why-section {
  height: 100%;
}
.cta-primary-why-section {
  align-items: flex-start;
  background-color: var(--sunglow);
  border: 1px none;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  left: 160px;
  padding: 16px 40px;
  position: absolute;
  top: 60%;
}
.cta-text-why-section {
  color: var(--shark);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-20px);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  white-space: nowrap;
}
.heading-why-section {
  left: 160px;
  letter-spacing: 0.8px;
  line-height: 60px;
  position: absolute;
  top: 10%;
  width: 65%;
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-40px);
  font-style: normal;
  font-weight: 600;
}
.flex-col-why-section {
  margin-left: -10%;
  min-height: 335px;
  display: inline-block;
  align-self: flex-end;
  width: 100%;
}
.carousel-indicator-why-section {
  display: flex;
  width: 100%;
}
.rect-in-focus-why-section {
  background-color: var(--white);
  border: 1px none;
  height: 4px;
  width: 100%;
}
.rect-why-section {
  background-color: var(--white);
  border: 1px none;
  height: 4px;
  margin-left: 10px;
  opacity: 0.15;
  width: 100%;
}
.card-container-why-section {
  align-items: flex-start;
  background-color: var(--white-3);
  border: 2px solid;
  border-color: var(--mako);
  border-radius: 16px;
  box-shadow: 0 12px 16px -4px #42403733;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  min-height: 240px;
  padding: 38px;
  width: 75%;
}
.card-heading-why-section {
  letter-spacing: 0;
  min-height: 34px;
  width: 100%;
  color: var(--sunglow);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-28px);
  font-style: normal;
  font-weight: 700;
}
.card-text-why-section {
  letter-spacing: 0;
  line-height: 25.6px;
  margin-top: 15px;
  min-height: 104px;
  opacity: 0.5;
  width: 100%;
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 400;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 70px !important;
  height: 5px !important;
  padding: 0;
  margin-right: 3px;
  margin-left: 10px !important;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10 solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.carousel-indicators {
  position: absolute;
  width: 50%;
  right: 0;
  bottom: 333px !important;
  left: 0 !important;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: -3rem !important;
  margin-left: 0% !important;
  list-style: none;
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .overlap-group1-why-section {
    margin-top: -30px;
  }
  .section-3-why-section {
    flex-direction: column;
    height: 640px;
  }
  .why-section-wrapper {
    flex-direction: column;
  }
  .heading-why-section {
    left: 0px;
    top: 8%;
    font-size: 40px;
    width: 100%;
    line-height: 150%;
    text-align: center;
  }
  .bg-asset-why-section {
    margin-left: -5%;
    margin-top: 5%;
  }
  .flex-col-why-section {
    margin-top: -200px;
    max-width: 100%;
    align-self: flex-start;
    margin-left: 20%;
  }
  .cta-primary-why-section {
    left: 45%;
    top: 130%;
  }
  .card-container-why-section {
    min-height: 100%;
    width: 60%;
    margin-left: 7%;
  }
  .carousel-indicators {
    bottom: 0px !important;
    top: -48px !important;
    left: 85px !important;
  }
  .card-heading-why-section {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .overlap-group1-why-section {
    margin-top: -30px;
  }
  .section-3-why-section {
    flex-direction: column;
    height: 640px;
  }
  .why-section-wrapper {
    flex-direction: column;
  }
  .heading-why-section {
    left: 3rem;
    top: 8%;
    font-size: 24px;
    width: 80%;
    line-height: 150%;
    text-align: center;
  }
  .bg-asset-why-section {
    margin-left: -5%;
    margin-top: 5%;
    height: 60%;
  }
  .flex-col-why-section {
    margin-top: -200px;
    max-width: 100%;
    align-self: center;
    margin-left: 30%;
    margin-right: 30%;
    width: 100%;
  }
  .cta-primary-why-section {
    left: 25%;
    top: 130%;
  }
  .card-container-why-section {
    height: 240px;
    width: 90%;
    margin-left: 20px;
  }
  .carousel-indicators {
    bottom: 0px !important;
    top: -48px !important;
    left: 85px !important;
  }
  .card-heading-why-section {
    font-size: 18px;
  }
  .card-text-why-section {
    font-size: 14px;
    line-height: 150%;
  }
}

.pricing-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  margin: auto;
  height: 100%;
  padding-bottom: 50px;
  background-color: var(--mirage);
}
.pricing-page-info-container {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: fit-content;
  width: 100%;
  max-width: 1500px;
  /* padding-top: 40px; */
}
.pricing-page-header-container {
  /* background-color: green; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5%;
  padding-bottom: 40px;
}
.pricing-page-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;

  letter-spacing: 0.02em;

  color: #ffcb45;
}
.pricing-page-container {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    #141624;
  border: 8px solid rgba(252, 232, 212, 0.12);
  padding-bottom: 50px;
  border-radius: 32px;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.pricing-page-tabs {
  width: 100%;
  height: 8%;
  padding-top: 50px;
  /* background-color: blue; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #41434e;
}
.pricing-page-tabs-container {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}
.new-banner {
  background: #ff9900;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2.5px 6px 3.5px;
  gap: 8px;
  width: 38px;
  height: 17px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 100%;
  margin-left: 6px;
  /* identical to box height, or 11px */

  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.recommended-banner {
  background: #1777fb;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2.5px 6px 3.5px;
  gap: 8px;
  width: fit-content;
  height: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 100%;
  margin-left: 6px;
  /* identical to box height, or 11px */

  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.pricing-page-tab-heading {
  width: fit-content;
  height: fit-content;
  /* background-color: yellow; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  /* identical to box height */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  padding-bottom: 1em;

  letter-spacing: 0.02em;

  color: #ffffff;
}
.pricing-page-tab-heading:hover {
  cursor: pointer;
  color: #ffffff;
  font-weight: 600;
  opacity: 1;
  border-bottom: 4px solid #ffcb45;
}
.pricing-page-tab-heading-selected {
  width: fit-content;
  height: fit-content;
  text-align: center;
  /* background-color: yellow; */
  font-family: "Inter";
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
  padding-bottom: 1em;
  /* identical to box height */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ffffff;
  font-weight: 600;
  opacity: 1;
  border-bottom: 4px solid #ffcb45;
}
.pricing-page-plans {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 93%;
  height: fit-content;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* background-color: #141624; */
}
.pricing-page-plan-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 50px;
  padding-top: 50px;

  /* background-color: #1777fb; */
}
.pricing-page-plan-info-container {
  width: 352px;
  height: 424px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.pricing-page-plan-info-container-combo {
  width: 385px;
  height: 650px;
  /* margin-left: 105px; */
  /* margin-top: 50px; */
  background: rgba(255, 255, 255, 0.04);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
li {
  /* list-style-type: disc; */
  list-style-position: inside;
  text-indent: -0.8em;
  padding-left: 1em;
}
.pricing-page-info-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  height: 20%;
  width: 80%;
  /* background-color: #141624; */
  /* identical to box height */
  color: #ffffff;
}
.pricing-page-info-subtext {
  color: var(--white);
  /* background-color: #ff9900; */
  font-size: 16px;
  width: 80%;
  height: 35%;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-inter);
  font-weight: 400;
  line-height: 170%;
  opacity: 0.7;
  border-bottom: 2px solid #3e404b;
}
ul {
  padding-left: 0 !important;
}
.pricing-page-price-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18%;
  width: 80%;
  /* background-color: red; */
}
.pricing-page-price {
  font-size: 32px;
  font-family: var(--font-family-inter);
  font-weight: 700;

  line-height: 40px;
  color: var(--white);
}
.pricing-page-price-span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  opacity: 0.5;
}
.pricing-page-schedulepay-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  gap: 10px;

  width: 80%;
  height: 56px;

  background: #ffc645;
  border-radius: 8px;
}
.pricing-page-schedulepay-button-combo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  gap: 10px;

  width: 80%;
  height: 56px;

  background: #ffc645;
  border-radius: 8px;
}
.pricing-pricing-info-button-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  color: #252627;
}
.pricing-page-plans-intro {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  height: 10%;
  padding-top: 2em;
  padding-bottom: 2em;
  width: 85%;
  /* background-color: red; */
  /* identical to box height, or 24px */

  color: #929292;
}
.pricing-page-plans-checklist {
  padding: 12px 24px;
  gap: 8px;
  width: 85%;
  height: 5%;
  background: #141624;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}
.pricing-page-plans-checklist-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #929292;
}
.pricing-page-plans-checklist-download {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-align: right;

  color: #ffc645;
}
@media only screen and (min-width: 760px) and (max-width: 1315px) {
  .pricing-page-plans {
    justify-content: center;
  }
  .pricing-page-plans-intro {
    text-align: center;
  }
  .pricing-page-tabs-container {
    width: 90%;
  }
}
@media only screen and (max-width: 760px) {
  .pricing-page-plans {
    justify-content: center;
  }
  .pricing-page-plans-intro {
    text-align: center;
  }
  .pricing-page-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.02em;
  }
  .pricing-page-tabs {
    padding-top: 20px;
  }
  .pricing-page-header-container {
    padding-bottom: 0px;
  }
  .pricing-page-tab-heading {
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 108px;
    height: 65px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
  .pricing-page-tab-heading-selected {
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 108px;
    height: 65px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
  .pricing-page-plans-intro {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }
  .pricing-page-plans-checklist {
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
  }
  .pricing-page-plans-checklist-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }
  .pricing-page-plans-checklist-download {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-align: inherit;
  }
  .pricing-page-container {
    background: none;
    border: none;
  }
  .pricing-page-info-heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  .pricing-page-info-subtext {
    font-weight: 400;
    font-size: 12px;
    line-height: 170%;
    height: 30%;
  }
  .pricing-page-plan-container {
    padding-left: 7px;
    padding-right: 7px;
  }
  .pricing-page-price {
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
  }
  .pricing-page-price-span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  .pricing-pricing-info-button-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 170%;
  }
  .pricing-page-plan-info-container {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    width: 312px;
    height: 354px;
  }
  .pricing-page-plan-info-container-combo {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    width: 340px;
    height: 454px;
  }
}
a {
  color: inherit !important;
  text-decoration: none !important;
}
a:hover {
  color: inherit !important;
  text-decoration: none !important;
}

.blogSection-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bastille);
  max-width: 1500px;
  width: 100%;
  height: fit-content;
  margin: auto;
  padding-bottom: 64px;
}
.blogSection-page {
  align-items: flex-start;
  display: flex;
  width: 100%;
  background-color: var(--bastille);
}
.blog-heading {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 115px;
  width: 86%;
  margin-top: 10px;
  color: white;
  font-family: var(--font-family-inter);
  border-bottom: 1px solid #585963;
}
.blog-themes {
  height: 120px;
  width: 86%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: green; */
  justify-content: flex-start;
  margin-bottom: 10px;
  /* flex-wrap: wrap; */
}
.blog-theme-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: red; */
  /* min-width: 200px; */
  width: 1000px;
  /* flex-wrap: wrap; */
}
.h2-blog {
  font-size: 32px;
  font-weight: 600;
}
.theme-heading {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  opacity: 0.5;
  min-width: 130px;
  /* background-color: purple; */
  margin-right: 15px;
  line-height: 24px;
  font-family: var(--font-family-inter);
}
.themes {
  width: fit-content;
  min-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  height: 35px;
  margin-left: 10px;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: var(--font-family-inter);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.themes:hover {
  cursor: pointer;
  background: #ffff;
  color: #000;
}
.themes-selected {
  width: fit-content;
  min-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  height: 35px;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: var(--font-family-inter);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #ffff;
  color: #000;
}
.blog-items-container {
  height: fit-content;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: -30px;
  /* background-color: purple; */
  padding-bottom: 30px;
}
.blog-items {
  height: 400px;
  width: 30.6%;
  display: flex;
  margin-left: 25px;
  margin-top: 25px;
  /* margin-left: 17px; */
  flex-direction: column;
  align-items: center;
  /* margin-top: 2%; */
  background: #383942;
  /* border: 1px solid #5e5e5e; */
  border-radius: 16px;
}
.blog-items:hover {
  cursor: pointer;
}
.blog-item-image {
  height: 55%;
  width: 100%;
  background: #d9d9d9;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.blog-item-heading {
  height: 30%;
  /* background-color: red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  width: 100%;
  /* background: green; */
  border-bottom: 1px solid #585963;
}
.blog-item-footer {
  height: 15%;
  /* background-color: purple; */
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.blog-item-headingh2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-top: 10px;
  height: 100%;
  /* width: 90%; */
  color: white;
  /* margin-left: 20px; */
  padding: 20px;
  /* background-color: red; */
}
.user-profile {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: green; */
  margin-left: 20px;
}
.date-container {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-right: 20px;
  /* background-color: red; */
}

.profile-pic {
  background: #ededed;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  margin-right: 12px;
}
.profile-name,
.date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: white;
  opacity: 0.5;
  margin-top: 10px;
  /* background-color: pink; */
}
.themes-subcategories {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.5;
  margin-top: 12px;
}
@media only screen and (min-width: 640px) and (max-width: 1080px) {
  .blog-items {
    width: 45%;
  }
  .blog-item-headingh2 {
    font-size: 18px;
  }
  .blog-theme-container {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-x: scroll;
  }
  .blog-theme-container::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

@media only screen and (max-width: 640px) {
  .blog-theme-container {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-x: scroll;
  }
  .blog-theme-container::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .blog-items {
    width: 98%;
    margin-left: 0px;
  }
  .theme-heading {
    display: none;
  }
  .blog-theme-container {
    justify-content: flex-start;
    /* margin-left: -10px; */
    /* background-color: red; */
    justify-content: space-between;
  }
  .blog-items {
    margin-top: 50px;
  }
  .blog-items-container {
    margin-top: -60px;
  }
}

.tos-section {
  background-color: var(--bastille);
  height: fit-content;
  width: 100%;
  overflow: hidden;
}
.tos-section-wrapper {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  margin: auto;
  max-width: 1500px;
}
.tos-logo {
  display: flex;
  justify-content: center;
  width: 100%;
}
.tos-heading {
  font-size: 48px;
  font-family: var(--font-family-poppins);
  color: var(--white);
  text-align: center;
  margin-top: 2%;
}
.tos-container {
  margin-left: 5%;
  margin-right: 5%;
}
.tos-subbtext-heading {
  font-size: 28px;
  font-family: var(--font-family-poppins);
  color: var(--white);
  text-align: left;
  margin-top: 5%;
}
.tos-subtext-paragraph {
  margin-top: 2%;
  font-size: 18px;
  opacity: 0.7;
  color: var(--white);
  text-align: left;
  font-family: var(--font-family-inter);
}
@media only screen and (max-width: 767px) {
  .tos-heading {
    font-size: 28px;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .tos-subtext-paragraph {
    font-size: 18px;
  }
  .tos-subbtext-heading {
    font-size: 22px;
  }
}

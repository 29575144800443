.faq-section {
  align-items: flex-start;
  display: flex;
  height: 100%;
  min-width: 100%;
  background-color: var(--bastille);
}
.faq-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 800px;
  padding: 112px 0;
  min-width: 100%;
}
.faq-heading {
  letter-spacing: 0.8px;
  margin-right: 1px;
  min-height: 48px;
  min-width: 107px;
  text-align: center;
  color: var(--sunglow);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-40px);
  font-weight: 800;
  font-style: normal;
}
.faq-subtext {
  color: var(--gray);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-22px);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 16px;
  min-height: 24px;
  min-width: 327px;
  white-space: nowrap;
}

.faqs {
  width: 85%;
  margin: 0 auto;
  padding: 15px;
  max-width: 1500px;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background-color: var(--white-2);
}
.border-closed {
  border-left: 5px solid #96979d;
}
.border-open {
  border-left: 5px solid var(--sunglow);
}

.faqs .faq .faq-question {
  color: var(--white);
  letter-spacing: 0;
  line-height: 30px;
  margin-left: 58px;
  min-height: 24px;
  min-width: 242px;
  white-space: nowrap;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-20px);
  font-weight: 700;
  font-style: normal;
  white-space: normal;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  color: white;
  margin-left: 58px;
  transition: all 0.4s ease-out;
  white-space: pre-line;
  font-weight: 400;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  line-height: 1.5;
  opacity: 0.7;
}
@media only screen and (max-width: 767px) {
  .faq-heading {
    font-size: 28px;
  }
  .faq-subtext {
    font-size: 18px;
  }
  .faqs .faq .faq-question {
    font-size: 16px;
    margin-left: 0px;
  }
  .faqs .faq .faq-answer {
    font-size: 14px;
  }
  .faqs {
    width: 100%;
  }
}

.hero-section {
  background-color: var(--mirage);
  height: 622px;
  width: 100%;
  overflow: hidden;
  margin-top: 40px;
}
.hero-section-wrapper {
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  margin: auto;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 58px;
  min-height: 482px;
  position: relative;
  width: 50%;
  margin-left: 100px;
}
.cta-primary {
  align-items: center;
  background-color: var(--sunglow);
  border: 1px none;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  margin-top: 46px;
  padding: 16px 40px;
}
.cta-text {
  color: #272525;
  font-family: var(--font-family-source_sans_pro);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 10px;
  margin-top: 5px;
  min-width: 131px;
  white-space: nowrap;
}
.scroll-indicator {
  align-self: flex-end;
  display: flex;
  height: 40px;
  margin-right: 44px;
  margin-top: 139px;
  width: 40px;
}
.chevrons-down-1 {
  height: 24px;
  margin-left: 8px;
  margin-top: 8px;
  width: 24px;
}
.hero-heading {
  color: #fff;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-48px);
  font-weight: 600;
  letter-spacing: 0.96px;
  line-height: 64.8px;
  min-height: 130px;
  width: 601px;
}
.hero-subtext {
  color: var(--gray);
  font-family: var(--font-family-inter);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 150%;
  min-height: 32px;
  width: 70%;
}
.hero-image {
  height: auto;
  width: 110%;
  display: inline-block;
}
.image-div {
  width: 45%;
  height: 100%;
  margin-right: 5%;
  margin-top: -40%;
  align-self: flex-end;
}
.hero-span-1 {
  font-family: var(--font-family-poppins);
  font-style: normal;
  font-weight: 600;
}
.hero-span-2 {
  color: var(--sunglow);
  font-family: var(--font-family-poppins);
  font-style: normal;
  font-weight: 600;
}
.sourcesanspro-bold-shark-20px {
  color: var(--shark);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-20px);
  font-style: normal;
  font-weight: 700;
}
@media only screen and (min-width: 501px) and (max-width: 767px) {
  .hero-section-wrapper {
    flex-direction: column;
  }
  .flex-col {
    margin-top: 0px;
    min-height: 0px;
    width: 70%;
    margin-left: 35px;
  }
  .hero-heading {
    font-size: 28px;
    line-height: 150%;
    min-height: 80px;
    width: 100%;
  }
  .hero-subtext {
    font-size: 14px;
    width: 80%;
    line-height: 150%;
  }
  .cta-text {
    font-size: 16px;
  }
  .cta-primary {
    margin-top: 35px;
    padding: 12px 16px;
  }
  .image-div {
    width: 72%;
    margin-top: -15%;
  }
}

@media only screen and (max-width: 500px) {
  .hero-section-wrapper {
    flex-direction: column;
  }
  .flex-col {
    margin-top: 0px;
    min-height: 0px;
    width: 80%;
    margin-left: 24px;
  }
  .hero-heading {
    font-size: 24px;
    line-height: 150%;
    min-height: 80px;
    width: 100%;
  }
  .hero-subtext {
    font-size: 12px;
    width: 100%;
    line-height: 150%;
  }
  .cta-text {
    font-size: 16px;
  }
  .cta-primary {
    margin-top: 35px;
    padding: 12px 16px;
  }
  .image-div {
    width: 90%;
    margin-top: 0%;
    margin-right: 10%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .flex-col {
    margin-left: 60px;
  }
}
@media only screen and (max-width: 768px) {
}

.blogPage-page {
  align-items: flex-start;
  display: flex;
  width: 100%;
  background-color: var(--bastille);
}
.blogPage-section {
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  margin: auto;
  height: fit-content;
  background-color: var(--bastille);
}
.blogPage-margin {
  width: 15%;
  margin-top: 20px;
}
.blogPage-container {
  width: 55%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 20px;
  /* background-color: green; */
}
.blogPage-relatedArticles {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  right: 0;
  height: 1%;
}
.blog-path {
  height: 50px;
  display: flex;
  flex-direction: row;
  /* background-color: yellow; */
}
.blog-content {
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 90%;
  /* background-color: green; */
}
.blog-footer {
  height: 150px;
  width: 80%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: row;
}
.blog-content-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  width: 95%;
  color: white;
}
.blog-content-authorInfo {
  height: 40px;
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  /* background-color: violet; */
}
.blog-content-profile {
  background: #ededed;
  width: 24px;
  height: 24px;
  border-radius: 8px;
}
.blog-content-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 3px;
  margin-left: 5px;
}
.blog-content-date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  margin-top: 3px;
  margin-left: 5px;
  color: rgba(255, 255, 255, 0.4);
}
.blog-content-para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin-top: 40px;
  /* or 150% */

  color: #ffffff;
}
.blog-footer-profilePic {
  background: #d9d9d9;
  border-radius: 40px;
  width: 80px;
  height: 80px;
  margin-top: 20px;
  margin-right: 20px;
}
.blog-footer-profileInfo {
  /* background-color: yellow; */
  width: 160px;
  height: 80px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.blog-footer-writtenBy {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #747474;
}
.blog-footer-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 117% */

  color: rgba(255, 255, 255, 0.8);
}
.blog-footer-date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: -5px;
  /* identical to box height, or 143% */

  color: rgba(255, 255, 255, 0.6);
}
.blog-path-common {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #ffffff;

  opacity: 0.5;
}
.blog-path-common:hover {
  cursor: pointer;
}
.blog-content-image {
  width: 100%;
  height: 250px;
  background: #d9d9d9;
  margin-top: 15px;
}
.blog-path-current {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #ffffff;
}
.blog-relatedArticlesList {
  height: 65%;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: 40%;

  /* background-color: yellow; */
}
.blog-relatedArticleHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5%;
  /* identical to box height, or 143% */

  color: #ffffff;
}
.relatedBlogs {
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding-right: 2px;
  margin-left: -20px;
  min-height: 90px;
  margin-bottom: 30px;
  justify-content: space-around;
  text-decoration: none !important;
  /* background-color: yellow; */
}
.relatedBlogsList {
  display: flex;
  /* background-color: red; */
  align-content: flex-start;
  flex-direction: column;
}
.relatedBlog-content {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.relatedBlogs-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-left: -10px;
  margin-top: 5px;
  /* or 150% */
  color: #dde0ed;
}
.relatedBlogs-date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-left: -10px;

  /* identical to box height, or 133% */
  color: #7f7f7f;
}
.relatedBlogs:hover {
  background: rgba(255, 255, 255, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-right: 2px;
  margin-left: -20px;
  min-height: 90px;
  margin-bottom: 30px;
  padding-right: 2px;
  margin-left: -20px;
  justify-content: space-around;
  cursor: pointer;
}
p {
  margin-bottom: 3rem !important;
}
.relatedBlog-Image {
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 1080px) {
  .blogPage-margin {
    width: 5%;
    margin-top: 20px;
  }
  .blogPage-container {
    width: 65%;
  }
  .blog-content-heading {
    /* background-color: red; */
    width: 95%;
  }
}
@media only screen and (max-width: 768px) {
  .blogPage-relatedArticles {
    display: flex;
    width: 100%;
    background-color: var(--mirage);
  }
  .blog-relatedArticlesList {
    margin-top: 60px;
    /* align-self: center; */
    /* margin-left: 3%; */
    /* margin-right: 3%; */
    width: 90%;
  }
  .blog-relatedArticleHeading {
    font-size: 20px;
    margin-left: 5%;
    align-self: flex-start;
  }
  .blogPage-section {
    flex-direction: column;
  }
  .blogPage-container {
    width: 100%;
    border: none;
    align-items: center;
  }
  .blogPage-margin {
    width: 5%;
    margin-top: 20px;
  }
  .blog-path {
    align-self: flex-start;
  }
  .blog-content-image {
    width: 100vw;
  }
  .blog-content {
    width: 100%;
  }
  .blog-content-heading {
    margin-left: 5%;
    width: 80%;
  }
  .blog-content-authorInfo {
    margin-left: 5%;
  }
  .blog-content-para {
    margin-left: 5%;
    margin-right: 5%;
  }
  .blog-path {
    margin-left: 3%;
  }
  .blog-footer {
    width: 90%;
    height: 200px;
  }
  .blogPage-relatedArticles {
    /* background-color: green; */
    justify-content: flex-start;
    align-items: center;
    height: 450px;
  }
  .relatedBlogs {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0px;
    /* background-color: red; */
    min-width: 300px;
    max-width: 500px;
    margin-left: 1%;
    /* margin-right: 2%; */
    margin-bottom: 10px;
  }
  .relatedBlogsList {
    width: 100%;
    /* align-items: center; */
  }
  .relatedBlog-Image {
    display: flex;
    background: #d9d9d9;
    border-radius: 6px;
    width: 96px;
    height: 72px;
  }
  .relatedBlogs-heading {
    margin-left: 0px;
  }
  .relatedBlog-content {
    width: 58%;
    margin-left: -20px;
    /* min-width: 100px; */
  }
  .relatedBlogs-date {
    margin-left: 0px;
    margin-top: 0px;
  }
  .relatedBlogs:hover {
    background: rgba(255, 255, 255, 0.12);
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0px;
    /* background-color: red; */
    min-width: 300px;
    margin-left: 1%;
    /* margin-right: 2%; */
    max-width: 500px;
    margin-bottom: 10px;
    /* border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 100px;
    padding-right: 2px;
    margin-left: -20px; */
    cursor: pointer;
  }
}

:root {
  --black: #000000;
  --gray: #919191;
  --shark: #252627;
  --sunglow: #ffc645;
  --white: #ffffff;
  --mirage: #141624;
  --alto: #d9d9d9;
  --mako: #444e54;
  --white-3: #ffffff0f;
  --white-2: #ffffff0a;
  --bastille: #20222f;
  --sonic-silver: #76767f;
  --white-4: #ffffff0d;
  --white-5: #ffffff80;
  --font-size-28px: 28px;
  --font-size-22px: 22px;
  --font-size-48px: 48px;
  --font-size-20px: 20px;
  --font-size-24px: 24px;
  --font-size-16px: 16px;
  --font-size-18px: 18px;
  --font-size-14px: 14px;
  --font-size-12px: 12px;
  --font-size-40px: 40px;
  --font-family-inter: "Inter", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
  --font-family-source_sans_pro: "Source Sans Pro", Helvetica;
}

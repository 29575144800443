.nav-section {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #141624;
}
.nav-section-container {
  height: 80px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  z-index: 1;
  width: 88%;
  max-width: 1500px;
  /* background-color: red; */
}
.nav-company-logo {
  width: 120px;
  cursor: pointer;

  /* background-color: green; */
}
.nav-items-container {
  width: 600px;
  /* background-color: purple; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.nav-links {
  color: #fff;
  cursor: pointer;
  font-family: "Inter", "Helvetica";
  opacity: 0.5;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 10px !important;
}
.nav-links-container {
  width: 55%;
  /* background-color: purple; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.nav-links:hover {
  color: var(--white) !important;
  opacity: 1;
}
.nav-button-book-session {
  align-items: center;
  background-color: var(--sunglow);
  border: 1px none;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  width: 122px;
  height: 44px;
  padding: 10px 20px;
  color: #272525;
  font-family: var(--font-family-source_sans_pro);
  font-size: 18px;
  text-decoration: none !important;
  font-weight: 1;
  margin-right: 10px;
}
.nav-buton-whatsapp {
  background: #373945;
  border-radius: 12px;
  border: 1px none;
  display: flex;
  padding: 10px 10px;
  color: #272525;
  font-size: 16px;
}
@media only screen and (max-width: 769px) {
  .nav-links-container {
    display: none;
  }
  .nav-items-container {
    width: fit-content;
  }
  .nav-buton-whatsapp {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 90px;
    margin-right: 20px;
  }
}
